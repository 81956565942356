<template>
  <div>
    <h1 class="text-h4">Управление скидками</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-title>
        Промокоды
        <v-spacer></v-spacer>
        <v-row dense>
          <v-col cols="5">
            <v-autocomplete
              v-model="table.filter.discount"
              label="Программа скидок"
              item-text="promotion"
              item-value="id"
              :items="discounts"
              clearable
              return-object
              @input="onInputFilter"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="table.filter.activity"
              label="Доступность"
              :items="activityItems"
              return-object
              @input="onInputFilter"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="table.filter.search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
              clearable
              @input="onInputFilter"
            ></v-text-field>
            <!--            <v-btn icon class="ml-2 mt-1" @click="toggleAdvancedSearch">-->
            <!--              <v-icon>{{ showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
            <!--            </v-btn>-->
          </v-col>
        </v-row>
      </v-card-title>
      <!--      <transition name="slide">-->
      <!--        <v-card-text v-show="showAdvancedSearch">-->
      <!--          Дополнительные фильтры-->
      <!--        </v-card-text>-->
      <!--      </transition>-->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="success"
              :disabled="busy"
              v-bind="attrs"
              :to="{ name: 'discount-code.create' }"
              v-on="on"
            >
              <v-icon>mdi-file-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Добавить</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="primary"
              :disabled="busy"
              class="ml-1"
              v-bind="attrs"
              :to="{ name: 'discount-code.generate' }"
              v-on="on"
            >
              <v-icon>mdi-creation-outline</v-icon>
            </v-btn>
          </template>
          <span>Сгенерировать коды</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="primary"
              :disabled="busy"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="exportDialog.show = true"
            >
              <v-icon>mdi-file-export-outline</v-icon>
            </v-btn>
          </template>
          <span>Экспорт</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="error"
              :disabled="!hasSelected"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedAction"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить группу</span>
        </v-tooltip>
      </v-card-actions>
      <v-data-table
        v-model="table.selectedItems"
        :loading="busy"
        :headers="table.headers"
        :items="items"
        :server-items-length="itemsTotalCount"
        :footer-props="table.footerProps"
        :options.sync="table.options"
        show-select
        :item-class="tableItemClass"
      >
        <template v-slot:item.id="{ item }">
          {{ item.id | shortenId }}
        </template>

        <template v-slot:item.isActive="{ item }">
          <v-icon v-if="item.inStock<=0" small color="error">
            mdi-stop-circle

          </v-icon>
          <v-icon v-else small color="success">
            mdi-play-circle
          </v-icon>
        </template>

        <template v-slot:item.info="{ item }">
          <span v-if="item.discount.discountTarget==='OrderLinesAmount'">
            <span v-if="item.discount.discountValueType==='Amount'">
              - {{ item.discount.discountValue | money }}
            </span>
            <span v-if="item.discount.discountValueType==='Rate'">
              - {{ item.discount.discountValue }} %
            </span>
            / Товары
          </span>
          <span v-else-if="item.discount.discountTarget==='ShippingAmount'">
            <span v-if="item.discount.discountValueType==='Amount'">
              - {{ item.discount.discountValue | money }}
            </span>
            <span v-if="item.discount.discountValueType==='Rate'">
              - {{ item.discount.discountValue }} %
            </span>
            / Доставка
          </span>
        </template>

        <template v-slot:item.code="{ item }">
          <router-link :to="{ name:'discount-code.edit',params: {id:item.id} }">{{ item.code }}</router-link>
        </template>

        <!--        <template v-slot:item.discount.promotion="{ item }">-->
        <!--          <router-link :to="{ name:'discount.edit',params: {id:item.discount.id} }">{{ item.discount.promotion }}</router-link>-->
        <!--        </template>-->

        <template v-slot:item.actions="{ item }">
          <v-btn
            fab
            text
            small
            :to="{ name: 'discount-code.edit', params: { id: item.id} }"
            class="mr-1"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            @click="deleteAction(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteItemConfirm">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="exportDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title primary--text">Экспорт промо кодов</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести экспорт данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="exportAction">
            Продолжить
          </v-btn>
          <v-btn text @click="exportDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import { Asc, Desc } from '@/model/SortOrder'
import Page from '@/model/Page'
import _ from 'lodash'
import DiscountCodeFilter from '@/model/discount/DiscountCodeFilter'
import DiscountCodeService from '@/service/DiscountCodeService'

export default {
  ...dashboardPageCommonAttributes,
  name: 'DiscountListPage',
  data: function () {
    return {
      table: {
        selectedItems: [],
        options: {
          sortBy: ['code'],
          sortDesc: [true],
          itemsPerPage: 30
        },
        headers: [
          { text: 'Код', value: 'code' },
          { text: '', value: 'isActive', sortable: false },
          { text: 'Выпущено', value: 'issued' },
          { text: 'В обороте', value: 'inStock' },
          { text: 'Программа', value: 'discount.promotion', sortable: false },
          { text: 'Скидка', value: 'info', sortable: false },
          { text: 'Действия', value: 'actions', sortable: false, align: 'right' }
        ],
        filter: {
          search: '',
          discount: null,
          activity: null
        },
        footerProps: {
          itemsPerPageOptions: [10, 15, 30, 50],
          itemsPerPageText: 'Строк на странице:',
          // showCurrentPage:true,
          showFirstLastPage: true
        }
      },
      activityItems: [
        { text: 'Все', value: 'All' },
        { text: 'В наличии', value: 'Active' },
        { text: 'Закончились', value: 'Blocked' }
      ],
      deleteDialog: {
        show: false,
        value: []
      },
      exportDialog: {
        show: false,
        value: []
      }
    }
  },
  computed: {
    ...mapState('discount_code_list', ['items', 'busy', 'itemsTotalCount', 'filter', 'showAdvancedSearch', 'discounts']),
    hasSelected() {
      return this.table.selectedItems.length > 0
    }
  },
  watch: {
    'table.options': {
      handler() {
        this.loadTable()
      },
      deep: true
    }
  },
  created() {
    this.table.filter.search = this.filter.search
    this.table.filter.discount = this.discounts.find((d) => d.id === this.filter.discountId)
    this.table.filter.activity = this.activityItems.find((d) => d.value === this.filter.activity)
  },
  methods: {
    ...mapMutations('discount_code_list', {
      setPage: 'SET_PAGE',
      setFilter: 'SET_FILTER',
      toggleAdvancedSearch: 'TOGGLE_ADVANCED_SEARCH',
      setBusy: 'SET_BUSY'
    }),
    ...mapActions('discount_code_list', ['fetchData', 'deleteDiscount']),
    loadTable() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.table.options

      const sort = []

      sortBy.forEach((field, index) => {
        sort.push({
          field,
          order: (sortDesc[index]) ? Desc : Asc
        })
      })

      const p = Page.create({
        page: page - 1,
        size: itemsPerPage,
        sort
      })

      this.setPage({ page: p })

      this.fetchData()
    },
    exportContent() {
      if (this.table.selectedItems.length > 0) {
        return Promise.resolve(this.table.selectedItems.map((i) => i.code).join('\n'))
      }
      const page = Page.create({
        page: 0,
        size: 100000,
        sort: [{ field:'code',order:'Asc' }]
      })

      return DiscountCodeService.find(this.filter, page)
        .then((result) => {
          console.log(result)

          return result.nodes.map((i) => i.code).join('\n')
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    exportAction() {
      this.exportDialog.show = false
      this.setBusy({ busy: true })
      this.exportContent().then((content) => {
        const link = document.createElement('a')
        const file = new Blob([content], { type: 'text/plain' })

        link.href = URL.createObjectURL(file)
        link.download = 'discount_codes.txt'
        link.click()
        URL.revokeObjectURL(link.href)
      })
        .catch((error) => console.error('Export discount codes error',error))
        .finally(() => this.setBusy({ busy: false }))
    },
    deleteAction(item) {
      this.deleteDialog.value = [item]
      this.deleteDialog.show = true
    },
    deleteSelectedAction() {
      this.deleteDialog.value = this.table.selectedItems
      this.deleteDialog.show = true
    },
    deleteItemConfirm() {
      const ids = this.deleteDialog.value.map((item) => item.id)

      this.deleteDiscount({ ids })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.deleteDialog.value = []
          this.deleteDialog.show = false
          this.table.selectedItems = []
        })
    },
    onInputFilter: _.debounce(function () {
      const discountId = this.table.filter.discount?.id ?? null
      const activity = this.table.filter.activity?.value ?? 'All'

      const filter = DiscountCodeFilter.create({
        search: this.table.filter.search,
        discountId,
        activity
      })

      this.table.options.page = 1

      this.setFilter({ filter })
      this.loadTable()
    }, 500),
    tableItemClass(item) {
      const dateEnd = new Date(item.discount.dateEnd)
      const now = new Date()

      if (dateEnd < now) {
        return ['expired']
      }

      return []
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Скидки'
      }
    }
  }
}
</script>

<style>
.theme--light tr.expired {
  background-color: lightgoldenrodyellow;
}

.theme--dark tr.expired {
  color: lightcoral;
}

</style>
